































import { Component, Prop, Vue } from 'vue-property-decorator'
import { MedicalBusiness } from '@/interface/api'

@Component({
  name: 'TreatmentReservationCompletePage',
})
export default class TreatmentReservationCompletePage extends Vue {
  @Prop({ default : ''}) private medicalBusinessId! :string
  @Prop({ default : () => null }) private medicalbusiness!: MedicalBusiness
}
